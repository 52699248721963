<template>
  <ca-card class="mb-3">
    <template #header>
      <div class="row">
        <div class="col">
          <h4 class="mb-0">
            2. Adresse
          </h4>
        </div>
        <div class="col-auto">
          <basic-switch
            v-model="isActive"
            :margin="false"
            :right="true"
            :swtich-state-name="{active: 'Aktiv', inactive: 'Inaktiv'}"
          />
        </div>
      </div>
    </template>
    <template #body>
      <p
        v-if="isActive"
        class="small m-0"
      >
        z.B. als Alternativadresse für Schriftwechsel
      </p>
      <b-collapse
        id="extraAddress"
        v-model="isActive"
      >
        <form
          novalidate
          @submit.prevent
        >
          <basic-checkbox
            v-model="workshop.address2.ADR2"
            label="Alternativadresse für Schriftwechsel verwenden"
            :margin="false"
            class="py-3"
          />

          <div class="form-row py-3">
            <div class="col-12 col-md-6">
              <basic-select
                v-model="workshop.address2.ANR2"
                :columns="{label: ['col-12', 'col-md-8'], input:['col-12', 'col-md-4']}"
                placeholder="Anrede"
                :horizontal="true"
                :margin="false"
                :required="isActive"
                label="Anrede"
                :options="humanGenOptions"
              />
            </div>
            <div class="col-12 col-md-3">
              <basic-select
                v-model="workshop.address2.TI12"
                :margin="false"
                placeholder="Titel"
                :options="humanTitOptions"
              />
            </div>
            <div class="col-12 col-md-3">
              <basic-select
                v-model="workshop.address2.TI22"
                :margin="false"
                placeholder="Titel"
                :options="humanPreOptions"
              />
            </div>
          </div>

          <template v-if="!isCompany">
            <basic-input
              v-model="workshop.address2.NAM2"
              :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
              placeholder="Nachname"
              :horizontal="true"
              label="Nachname"
              :required="isActive"
              :margin="false"
              class="pb-3"
            />

            <basic-input
              v-model="workshop.address2.VOR2"
              :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
              placeholder="Vorname"
              :horizontal="true"
              label="Vorname"
              :required="isActive"
              :margin="false"
              class="pb-3"
            />
          </template>

          <basic-input
            v-else
            v-model="workshop.address.NAM2"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            placeholder="Firmenname"
            :horizontal="true"
            label="Firma"
            :margin="false"
            class="pb-3"
            :required="isActive"
          />

          <basic-input
            v-model="workshop.address2.NA22"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            placeholder="Zusatz"
            :horizontal="true"
            label="Zusatz"
            :margin="false"
            class="pb-3"
          />

          <basic-input
            v-model="workshop.address2.STR2"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            placeholder="Straße, Nr."
            :horizontal="true"
            label="Straße, Nr."
            :required="isActive"
            :margin="false"
            class="pb-3"
          />

          <div class="form-row mb-3">
            <div class="col-12 col-md-6">
              <basic-input
                v-if="$system === 'at'"
                v-model="workshop.address2.PLZ2"
                name="PLZ"
                :columns="{label: ['col-12', 'col-md-8'], input:['col-12', 'col-md-4']}"
                placeholder="PLZ"
                :horizontal="true"
                label="PLZ / Ort"
                :margin="false"
                :required="isActive"
              />
              <basic-typeahead
                v-else
                id="city-zip2"
                v-model="workshop.address2.PLZ2"
                name="PLZ"
                auto-select-first
                :columns="{label: ['col-12', 'col-md-8'], input:['col-12', 'col-md-4']}"
                placeholder="PLZ"
                :query="searchCityByZip"
                :input-formatter="city => city.zip.toString()"
                :result-formatter="cityResultFormatter"
                :horizontal="true"
                label="PLZ / Ort"
                :margin="false"
                :required="isActive"
                @hit="setCity"
              />
            </div>
            <div class="col-12 col-md-6">
              <basic-input
                v-if="$system === 'at'"
                v-model="workshop.address2.ORT2"
                name="Stadt"
                autocomplete="off"
                :margin="false"
                :required="isActive"
                placeholder="Ort"
              />
              <basic-typeahead
                v-else
                id="city-name2"
                v-model="workshop.address2.ORT2"
                name="Stadt"
                auto-select-first
                autocomplete="off"
                :margin="false"
                :query="searchCityByName"
                :input-formatter="city => city.name.toString()"
                :result-formatter="cityResultFormatter"
                :required="isActive"
                placeholder="Ort"
                @hit="setCity"
              />
            </div>
          </div>

          <div class="form-row pb-3">
            <div class="col-12 col-md-6">
              <basic-typeahead
                id="country-typeahead2"
                v-model="workshop.address2.NAT2.char"
                name="Land"
                :columns="{label: ['col-12', 'col-md-8'], input:['col-12', 'col-md-4']}"
                placeholder="Land"
                :horizontal="true"
                label="Land"
                :margin="false"
                :query="searchCountry"
                :input-formatter="country => country.char.toString()"
                :result-formatter="countryResultFormatter"
                :required="isActive"
                @input="country = ''"
                @hit="setCountry"
              />
            </div>
            <div class="col-12 col-md-6">
              <basic-input
                v-model="country"
                :margin="false"
                placeholder="Land"
                disabled
              />
            </div>
          </div>

          <div class="form-row pb-3">
            <div class="col-12 col-md-6">
              <basic-input
                v-if="$system === 'at'"
                v-model="workshop.address2.PLPOF2"
                name="PLZ"
                :columns="{label: ['col-12', 'col-md-8'], input:['col-12', 'col-md-4']}"
                placeholder="PLZ"
                :horizontal="true"
                label="PLZ Postfach"
                :margin="false"
              />
              <basic-typeahead
                v-else
                id="city-zip-po2"
                v-model="workshop.address2.PLPOF2"
                name="PLZ"
                auto-select-first
                :columns="{label: ['col-12', 'col-md-8'], input:['col-12', 'col-md-4']}"
                placeholder="PLZ"
                :query="searchCityByZip"
                :input-formatter="city => city.zip.toString()"
                :result-formatter="cityResultFormatter"
                :horizontal="true"
                label="PLZ Postfach"
                :margin="false"
                @hit="setCityPo"
              />
            </div>
            <div class="col-12 col-md-6">
              <basic-input
                v-if="$system === 'at'"
                v-model="workshop.address2.POF2"
                name="Stadt"
                autocomplete="off"
                :margin="false"
                placeholder="Ort"
              />
              <basic-typeahead
                v-else
                id="city-name-po2"
                v-model="workshop.address2.POF2"
                name="Stadt"
                auto-select-first
                autocomplete="off"
                :margin="false"
                :query="searchCityByName"
                :input-formatter="city => city.name.toString()"
                :result-formatter="cityResultFormatter"
                placeholder="Ort"
                @hit="setCityPo"
              />
            </div>
          </div>

          <hr>

          <basic-input
            v-model="workshop.address2.contact.TEF2"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            placeholder="Festnetznummer"
            :horizontal="true"
            label="Festnetztelefon"
            :margin="false"
            class="py-3"
          />

          <basic-tel-input
            v-model="workshop.address2.contact.MOB2"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            :horizontal="true"
            placeholder="Mobilfunknummer"
            label="Mobiltelefon"
            :margin="false"
            class="pb-3"
          />

          <basic-input
            v-model="workshop.address2.contact.FAX2"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            placeholder="Telefaxnummer"
            :horizontal="true"
            label="Telefax"
            :margin="false"
            class="pb-3"
          />

          <basic-input
            v-model="workshop.address2.contact.MAIL2"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            placeholder="E-Mail Adresse"
            :horizontal="true"
            label="E-Mail"
            :margin="false"
            class="pb-3"
          />

          <hr>

          <basic-checkbox
            v-model="privateAddress"
            label="Privatdaten hinzufügen"
            :margin="false"
            class="py-3"
          />

          <b-collapse
            id="privateDataCollapse"
            v-model="privateAddress"
          >
            <basic-input
              v-model="workshop.address2.contact.TEL2"
              :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
              placeholder="Festnetznummer"
              :horizontal="true"
              label="Festnetztelefon"
              :margin="false"
              class="py-3"
            />

            <basic-tel-input
              v-model="workshop.address2.contact.MOBIL2_PRIVAT"
              :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
              :horizontal="true"
              placeholder="Mobilfunknummer"
              label="Mobiltelefon"
              :margin="false"
              class="pb-3"
            />

            <basic-input
              v-model="workshop.address2.contact.FAX2_PRIVAT"
              :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
              placeholder="Telefaxnummer"
              :horizontal="true"
              label="Telefax"
              :margin="false"
              class="pb-3"
            />

            <basic-input
              v-model="workshop.address2.contact.MAIL2_PRIVAT"
              :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
              placeholder="E-Mail Adresse"
              :horizontal="true"
              label="E-Mail"
              :margin="false"
              class="pb-3"
            />
          </b-collapse>
        </form>
      </b-collapse>
    </template>
  </ca-card>
</template>

<script>
import BasicInput from '@/components/BaseComponents/BasicInput.vue'
import BasicSelect from '@/components/BaseComponents/BasicSelect.vue'
import BasicTypeahead from '@/components/BaseComponents/BasicTypeahead.vue'
import CaCard from '@/components/Card.vue'
import BasicCheckbox from '../../BaseComponents/BasicCheckbox.vue'
import BasicTelInput from '@/components/BaseComponents/BasicTelInput.vue'
import BasicSwitch from '@/components/BaseComponents/BasicSwitch.vue'
import TitleSelectMixin from '@/components/Forms/title-select-mixin.js'

export default {
  name: 'PartnerExtraAddressForm',
  components: {
    BasicInput,
    BasicSelect,
    CaCard,
    BasicTypeahead,
    BasicCheckbox,
    BasicTelInput,
    BasicSwitch
  },
  mixins: [TitleSelectMixin],
  props: {
    workshop: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      country: '',
      privateAddress: false,
      isActive: false
    }
  },
  computed: {
    isCompany () {
      return this.workshop.address2.ANR2 === 'f'
    }
  },
  watch: {
    workshop: {
      immediate: true,
      async handler () {
        if (!this.isActive && this.workshop.address2.NAM2) {
          this.isActive = true
        }
        if (!this.country && this.workshop.address2.NAT2.char) {
          this.country = (await this.searchCountry(this.workshop.address2.NAT2.char)).data[0].name
        }
        const contact = this.workshop.address2.contact
        if (!this.privateAddress && (contact.TEL2 || contact.MOBIL2_PRIVAT || contact.FAX2_PRIVAT || contact.MAIL2_PRIVAT)) {
          this.privateAddress = true
        }
      }
    }
  },
  methods: {
    searchCityByZip (zip) {
      return this.$store.dispatch('zipcodes/find', {
        query: {
          $limit: 50,
          zip: { $regex: '^' + zip, $options: 'i' }
        }
      })
    },
    searchCityByName (name) {
      return this.$store.dispatch('zipcodes/find', {
        query: {
          $limit: 50,
          name: { $regex: '^' + name, $options: 'i' }
        }
      })
    },
    cityResultFormatter (city) {
      return `${city.zip} ${city.name}`
    },
    setCity (city) {
      if (city.zip) {
        this.workshop.address2.PLZ2 = city.zip
      }
      if (city.name) {
        this.workshop.address2.ORT2 = city.name
      }
    },
    setCityPo (city) {
      if (city.zip) {
        this.workshop.address2.PLPOF2 = city.zip
      }
      if (city.name) {
        this.workshop.address2.POF2 = city.name
      }
    },
    searchCountry (char) {
      return this.$store.dispatch('countries/find', {
        query: {
          $limit: 10, char: char.toUpperCase()
        }
      })
    },
    countryResultFormatter (NAT) {
      return `${NAT.char} ${NAT.name}`
    },
    setCountry (NAT) {
      this.country = NAT.name
    }
  }
}
</script>
