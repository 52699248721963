<template>
  <b-card
    no-body
    header
    class="mb-2"
  >
    <template #header>
      <div
        class="row"
        @click="visible = !visible"
      >
        <div class="col d-flex align-items-center">
          <h6 class="mb-0">
            {{ bankDetails.VWZ }}
          </h6>
          <span
            v-if="isPrimary"
            class="badge badge-dark ml-2"
          >
            Primärkonto
          </span>
        </div>
        <div class="col-auto">
          <button
            class="btn bg-transparent p-0"
          >
            <i
              class="far fa-fw"
              :class="{'fa-chevron-down': visible, 'fa-chevron-right': !visible}"
            />
          </button>
        </div>
      </div>
    </template>
    <b-card-body :class="{'p-0': !visible}">
      <b-collapse
        id="kontodaten"
        v-model="visible"
      >
        <div class="row">
          <div class="col-12">
            <b-tabs class="tabs">
              <b-tab
                title="Kontodaten"
                active
              >
                <div class="bg-white border border-gray-2 border-top-0 rounded-bottom mb-3">
                  <div class="card-body">
                    <form
                      novalidate
                      @submit.prevent
                    >
                      <div class="form-row my-3">
                        <div class="col-12 col-md-8">
                          <basic-input
                            v-model="bankDetails.IBAN"
                            :columns="{label: ['col-12', 'col-md-6'], input:['col-12', 'col-md-6']}"
                            placeholder="IBAN"
                            :horizontal="true"
                            label="IBAN"
                            :margin="false"
                          />
                        </div>
                        <div class="col-12 col-md-4">
                          <basic-input
                            v-model="bankName"
                            :margin="false"
                            placeholder="Bank"
                            :disabled="nameIsDisabled"
                          />
                        </div>
                      </div>

                      <basic-input
                        v-model="bankDetails.VWZ"
                        :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
                        placeholder="Kontobeschreibung"
                        :horizontal="true"
                        label="Kontobeschreibung"
                      />

                      <hr>

                      <div class="form-row py-3">
                        <div class="col-12 col-md-4">
                          Abweichender Kontoinhaber
                        </div>
                        <div class="col-12 col-md-8">
                          <basic-checkbox
                            v-model="diffHolder"
                            :margin="false"
                          />
                        </div>
                      </div>

                      <b-collapse
                        id="kontoRevoked"
                        v-model="diffHolder"
                      >
                        <basic-input
                          v-model="bankDetails.diffHolder.ABWNAM"
                          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
                          placeholder="Nachname"
                          :horizontal="true"
                          label="Nachname"
                          :required="diffHolder"
                        />

                        <basic-input
                          v-model="bankDetails.diffHolder.ABWVOR"
                          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
                          placeholder="Vorname"
                          :horizontal="true"
                          label="Vorname"
                          :required="diffHolder"
                        />

                        <basic-input
                          v-model="bankDetails.diffHolder.ABWSTR"
                          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
                          placeholder="Straße, Nr."
                          :horizontal="true"
                          label="Straße, Nr."
                          :required="diffHolder"
                        />

                        <div class="form-row mb-3">
                          <div class="col-12 col-md-6">
                            <basic-input
                              v-if="$system === 'at'"
                              v-model="bankDetails.diffHolder.ABWPLZ"
                              name="PLZ"
                              :columns="{label: ['col-12', 'col-md-8'], input:['col-12', 'col-md-4']}"
                              placeholder="PLZ"
                              :horizontal="true"
                              label="PLZ / Ort"
                              :margin="false"
                              :required="diffHolder"
                            />
                            <basic-typeahead
                              v-else
                              id="account-zip"
                              v-model="bankDetails.diffHolder.ABWPLZ"
                              name="PLZ"
                              auto-select-first
                              :columns="{label: ['col-12', 'col-md-8'], input:['col-12', 'col-md-4']}"
                              placeholder="PLZ"
                              :query="searchCityByZip"
                              :input-formatter="city => city.zip.toString()"
                              :result-formatter="cityResultFormatter"
                              :horizontal="true"
                              label="PLZ / Ort"
                              :margin="false"
                              :required="diffHolder"
                              @hit="setCity"
                            />
                          </div>
                          <div class="col-12 col-md-6">
                            <basic-input
                              v-if="$system === 'at'"
                              v-model="bankDetails.diffHolder.ABWORT"
                              name="Stadt"
                              autocomplete="off"
                              :margin="false"
                              :required="diffHolder"
                              placeholder="Ort"
                            />
                            <basic-typeahead
                              id="account-name"
                              v-model="bankDetails.diffHolder.ABWORT"
                              name="Stadt"
                              auto-select-first
                              autocomplete="off"
                              :margin="false"
                              :query="searchCityByName"
                              :input-formatter="city => city.name.toString()"
                              :result-formatter="cityResultFormatter"
                              :required="diffHolder"
                              placeholder="Ort"
                              @hit="setCity"
                            />
                          </div>
                        </div>
                      </b-collapse>
                    </form>
                  </div>
                </div>
              </b-tab>
              <b-tab
                title="SEPA Mandat"
                lazy
              >
                <div class="bg-white border border-gray-2 border-top-0 rounded-bottom mb-3">
                  <div class="card-body">
                    <form
                      novalidate
                      @submit.prevent
                    >
                      <basic-input
                        v-model="bankDetails.sepa.SEPAMANDAT"
                        :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
                        placeholder="Mandats-Nr."
                        :horizontal="true"
                        label="SEPA-Mandats-Nr."
                      />

                      <div class="form-row mt-3">
                        <div class="col-12 col-md-8">
                          <basic-datepicker
                            v-model="bankDetails.sepa.MANDANF"
                            :columns="{label: ['col-12', 'col-md-6'], input:['col-12', 'col-md-6']}"
                            :horizontal="true"
                            label="Mandat angefordert / erhalten"
                            placeholder="angefordert (TT.MM.JJJJ)"
                            :margin="false"
                          />
                        </div>
                        <div class="col-12 col-md-4">
                          <basic-datepicker
                            v-model="bankDetails.sepa.MANDERH"
                            :horizontal="true"
                            :columns="{label: ['col-12', 'col-md-6'], input:['col-12']}"
                            placeholder="erhalten (TT.MM.JJJJ)"
                            :margin="false"
                          />
                        </div>
                      </div>

                      <div
                        class="form-row mb-3"
                      >
                        <div class="col-12 col-md-8 ml-auto">
                          <small>ACHTUNG: Mandat gültig für 36 Monate nach letzter Abbuchung</small>
                        </div>
                      </div>

                      <div class="form-row mt-3">
                        <div class="col-12 col-md-8">
                          <basic-input
                            v-model="bankDetails.sepa.LSTART"
                            name="Lastschrift Art / Status"
                            :columns="{label: ['col-12', 'col-md-6'], input:['col-12', 'col-md-6']}"
                            placeholder="Lastschrift-Art"
                            :horizontal="true"
                            label="Lastschrift-Art / -Status"
                            :margin="false"
                          />
                        </div>
                        <div class="col-12 col-md-4">
                          <basic-input
                            v-model="bankDetails.sepa.LSTSTATUS"
                            :margin="false"
                            placeholder="Lastschrift-Status"
                          />
                        </div>
                      </div>

                      <div
                        class="form-row mb-3"
                      >
                        <div class="col-12 col-md-8 ml-auto">
                          <small>Lastschrift-Art und -Status müssen für das Clearing ausgewählt werden.</small>
                        </div>
                      </div>

                      <basic-datepicker
                        v-model="bankDetails.sepa.MANDATAKTV"
                        :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
                        :horizontal="true"
                        label="Letzte Abbuchung am"
                        placeholder="TT.MM.JJJJ"
                        :margin="false"
                      />

                      <hr>

                      <div class="form-row py-3">
                        <div class="col-12 col-md-4">
                          Mandat aufgehoben
                        </div>
                        <div class="col-12 col-md-8">
                          <basic-checkbox
                            v-model="isRevoked"
                            :margin="false"
                          />
                        </div>
                      </div>

                      <b-collapse
                        id="mandatCollapse"
                        v-model="isRevoked"
                      >
                        <basic-datepicker
                          v-model="bankDetails.sepa.MANDATENDE"
                          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
                          :horizontal="true"
                          label="Mandat aufgehoben am"
                          placeholder="TT.MM.JJJJ"
                          :margin="false"
                          :required="isRevoked"
                        />

                        <basic-input
                          v-model="bankDetails.sepa.ENDEGRUND"
                          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
                          placeholder="Grund"
                          :horizontal="true"
                          label="Aufhebungsgrund"
                        />
                      </b-collapse>
                    </form>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
            <div
              v-if="index !== 0"
              class="row"
            >
              <div class="col d-flex justify-content-end">
                <button
                  class="btn btn-outline-danger"
                  @click="$emit('remove-bank', index)"
                >
                  <i class="far fa-trash-alt mr-2" /> Konto entfernen
                </button>
              </div>
            </div>
          </div>
        </div>
      </b-collapse>
    </b-card-body>
  </b-card>
</template>
<script>
import BasicInput from '@/components/BaseComponents/BasicInput'
import BasicTypeahead from '@/components/BaseComponents/BasicTypeahead'
import BasicDatepicker from '@/components/BaseComponents/BasicDatepicker'
import BasicCheckbox from '@/components/BaseComponents/BasicCheckbox'
import iban from 'iban'

export default {
  name: 'BankCollapseItem',
  components: {
    BasicInput,
    BasicTypeahead,
    BasicDatepicker,
    BasicCheckbox
  },
  props: {
    bankDetails: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      default: 0
    },
    editMode: {
      type: Boolean
    }
  },
  data () {
    return {
      loading: false,
      nameIsDisabled: true,
      visible: true,
      bankName: '',
      isPrimary: false,
      diffHolder: false,
      isRevoked: false
    }
  },
  computed: {
    number () {
      return this.index + 1
    }
  },
  watch: {
    'bankDetails.IBAN': {
      immediate: true,
      handler: 'findCreditInstitution'
    },
    'bankDetails.diffHolder': {
      immediate: true,
      handler () {
        if (!this.diffHolder && this.bankDetails.diffHolder.ABWNAM) {
          this.diffHolder = true
        }
      }
    },
    bankDetails: {
      immediate: true,
      handler () {
        if (!this.isRevoked && (this.bankDetails.MANDATENDE || this.bankDetails.ENDEGRUND)) {
          this.isRevoked = true
        }
      }
    }
  },
  created () {
    if (this.index === 0) {
      this.isPrimary = true
    }
    if (this.editMode && this.index > 0) {
      this.visible = false
    }
  },
  methods: {
    searchCityByZip (zip) {
      return this.$store.dispatch('zipcodes/find', {
        query: {
          $limit: 50,
          zip: { $regex: '^' + zip, $options: 'i' }
        }
      })
    },
    searchCityByName (name) {
      return this.$store.dispatch('zipcodes/find', {
        query: {
          $limit: 50,
          name: { $regex: '^' + name, $options: 'i' }
        }
      })
    },
    cityResultFormatter (city) {
      return `${city.zip} ${city.name}`
    },
    setCity (city) {
      if (city.zip) {
        this.bankDetails.diffHolder.ABWPLZ = city.zip
      }
      if (city.name) {
        this.bankDetails.diffHolder.ABWORT = city.name
      }
    },
    async findCreditInstitution () {
      if (this.bankDetails.IBAN) {
        const valid = iban.isValid(this.bankDetails.IBAN)

        if (valid) {
          let blz
          if (this.bankDetails.IBAN.substr(0, 2) === 'DE') {
            blz = this.bankDetails.IBAN.substr(4, 8)
          } else if (this.bankDetails.IBAN.substr(0, 2) === 'AT') {
            blz = this.bankDetails.IBAN.substr(4, 5)
          }
          const bank = await this.$store.dispatch('credit-institution/find', { query: { NAT: this.bankDetails.IBAN.substr(0, 2), BLZ: blz }, $limit: 1 })
          if (bank.data.length !== 0) {
            this.nameIsDisabled = true
            this.bankName = bank.data[0].NAME
          } else {
            this.nameIsDisabled = false
          }
        } else if (this.bankName) {
          this.bankName = ''
        }
      }
    }
  }
}
</script>
