import { render, staticRenderFns } from "./BasicSwitch.vue?vue&type=template&id=336538fa&scoped=true&"
import script from "./BasicSwitch.vue?vue&type=script&lang=js&"
export * from "./BasicSwitch.vue?vue&type=script&lang=js&"
import style0 from "./BasicSwitch.vue?vue&type=style&index=0&id=336538fa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "336538fa",
  null
  
)

export default component.exports