<template>
  <ca-card class="mb-3">
    <template #header>
      <h4 class="mb-0">
        Partnerdaten
      </h4>
    </template>
    <template #body>
      <form
        novalidate
        @submit.prevent
      >
        <div class="form-row my-3">
          <div class="col-12 col-md-6">
            <basic-select
              v-model="workshop.address.ANR"
              :columns="{label: ['col-12', 'col-md-8'], input:['col-12', 'col-md-4']}"
              placeholder="Anrede"
              :horizontal="true"
              :margin="false"
              required
              label="Anrede"
              :options="humanGenOptions"
            />
          </div>
          <div class="col-12 col-md-3">
            <basic-select
              v-model="workshop.address.TI1"
              :margin="false"
              placeholder="Titel"
              :options="humanTitOptions"
            />
          </div>
          <div class="col-12 col-md-3">
            <basic-select
              v-model="workshop.address.TI2"
              :margin="false"
              placeholder="Titel"
              :options="humanPreOptions"
            />
          </div>
        </div>

        <template v-if="!isCompany">
          <basic-input
            v-model="workshop.address.NAME.NAM"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            placeholder="Nachname"
            :horizontal="true"
            label="Nachname"
            required
          />

          <basic-input
            v-model="workshop.address.NAME.VOR"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            placeholder="Vorname"
            :horizontal="true"
            label="Vorname"
          />
        </template>

        <basic-input
          v-else
          v-model="workshop.address.NAME.NAM"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Firmenname"
          :horizontal="true"
          label="Firma"
          required
        />

        <basic-input
          v-model="workshop.address.NAME.NA2"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Zusatz"
          :horizontal="true"
          label="Zusatz"
        />

        <basic-input
          v-model="workshop.address.STR"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Straße, Nr."
          :horizontal="true"
          label="Straße, Nr."
          required
        />

        <div class="form-row my-3">
          <div class="col-12 col-md-6">
            <basic-input
              v-if="$system === 'at'"
              v-model="workshop.address.PLZ"
              name="PLZ"
              :columns="{label: ['col-12', 'col-md-8'], input:['col-12', 'col-md-4']}"
              placeholder="PLZ"
              :horizontal="true"
              label="PLZ / Ort"
              :margin="false"
              required
            />
            <basic-typeahead
              v-else
              id="city-zip"
              v-model="workshop.address.PLZ"
              name="PLZ"
              auto-select-first
              :columns="{label: ['col-12', 'col-md-8'], input:['col-12', 'col-md-4']}"
              placeholder="PLZ"
              :query="searchCityByZip"
              :input-formatter="city => city.zip.toString()"
              :result-formatter="cityResultFormatter"
              :horizontal="true"
              label="PLZ / Ort"
              :margin="false"
              required
              @hit="setCity"
            />
          </div>
          <div class="col-12 col-md-6">
            <basic-input
              v-if="$system === 'at'"
              v-model="workshop.address.ORT"
              name="Stadt"
              autocomplete="off"
              :margin="false"
              required
              placeholder="Ort"
            />
            <basic-typeahead
              id="city-name"
              v-model="workshop.address.ORT"
              name="Stadt"
              auto-select-first
              autocomplete="off"
              :margin="false"
              :query="searchCityByName"
              :input-formatter="city => city.name.toString()"
              :result-formatter="cityResultFormatter"
              required
              placeholder="Ort"
              @hit="setCity"
            />
          </div>
        </div>

        <div class="form-row my-3">
          <div class="col-12 col-md-6">
            <basic-typeahead
              id="country-typeahead"
              v-model="workshop.address.NAT.char"
              name="Land"
              :columns="{label: ['col-12', 'col-md-8'], input:['col-12', 'col-md-4']}"
              placeholder="Land"
              :horizontal="true"
              label="Land"
              :margin="false"
              :query="searchCountry"
              :input-formatter="country => country.char.toString()"
              :result-formatter="countryResultFormatter"
              required
              @input="country = ''"
              @hit="setCountry"
            />
          </div>
          <div class="col-12 col-md-6">
            <basic-input
              v-model="country"
              :margin="false"
              placeholder="Land"
              disabled
            />
          </div>
        </div>

        <div class="form-row pb-3">
          <div class="col-12 col-md-6">
            <basic-input
              v-if="$system === 'at'"
              v-model="workshop.address.PL2"
              name="PLZ"
              :columns="{label: ['col-12', 'col-md-8'], input:['col-12', 'col-md-4']}"
              placeholder="PLZ"
              :horizontal="true"
              label="PLZ Postfach"
              :margin="false"
            />
            <basic-typeahead
              v-else
              id="city-zip-po"
              v-model="workshop.address.PL2"
              name="PLZ"
              auto-select-first
              :columns="{label: ['col-12', 'col-md-8'], input:['col-12', 'col-md-4']}"
              placeholder="PLZ"
              :query="searchCityByZip"
              :input-formatter="city => city.zip.toString()"
              :result-formatter="cityResultFormatter"
              :horizontal="true"
              label="PLZ Postfach"
              :margin="false"
              @hit="setCityPo"
            />
          </div>
          <div class="col-12 col-md-6">
            <basic-input
              v-if="$system === 'at'"
              v-model="workshop.address.POF"
              name="Stadt"
              autocomplete="off"
              :margin="false"
              placeholder="Ort"
            />
            <basic-typeahead
              id="city-name-po"
              v-model="workshop.address.POF"
              name="Stadt"
              auto-select-first
              autocomplete="off"
              :margin="false"
              :query="searchCityByName"
              :input-formatter="city => city.name.toString()"
              :result-formatter="cityResultFormatter"
              placeholder="Ort"
              @hit="setCityPo"
            />
          </div>
        </div>

        <hr>

        <div class="form-row py-3">
          <div class="col-12 col-md-4">
            Spezifischer Empfänger<br>(zu Händen)
          </div>
          <div class="col-12 col-md-8 d-flex align-items-center">
            <basic-checkbox
              v-model="specialRec"
              :margin="false"
            />
          </div>
        </div>

        <b-collapse
          id="recipientCollapse"
          v-model="specialRec"
        >
          <basic-select
            id="title-gen2"
            v-model="workshop.address.ANB1"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            placeholder="Anrede"
            :horizontal="true"
            :margin="false"
            :required="specialRec"
            label="Anrede"
            class="pb-3"
            :options="[
              {label: 'Herr', value: 'm'},
              {label: 'Frau', value: 'w'}
            ]"
          />

          <div class="form-row pb-3">
            <div class="col-12 col-md-8">
              <basic-input
                v-model="workshop.address.BEV1"
                name="surname2"
                :columns="{label: ['col-12', 'col-md-6'], input:['col-12', 'col-md-6']}"
                placeholder="Vorname"
                :horizontal="true"
                label="Vorname / Nachname"
                :margin="false"
                :required="specialRec"
              />
            </div>
            <div class="col-12 col-md-4">
              <basic-input
                id="name2"
                v-model="workshop.address.BEZ"
                :margin="false"
                placeholder="Nachname"
                :required="specialRec"
              />
            </div>
          </div>

          <div class="pb-3">
            <basic-input
              v-model="workshop.address.SGE"
              :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
              placeholder="Individualanrede"
              :horizontal="true"
              label="Individualanrede"
              :margin="false"
            />
          </div>
          <div class="form-group mb-0">
            <div class="form-row pb-3">
              <div class="col-12 col-md-4">
                Bemerkung
              </div>
              <div
                class="col-12 col-md-8"
              >
                <basic-textarea
                  v-model="workshop.address.BEMERKUNG01"
                  placeholder="Bemerkungen"
                  :columns="{label: ['col-12', 'col-md-8'], input:['col-12', 'col-md-4']}"
                  :margin="false"
                />
              </div>
            </div>
          </div>
        </b-collapse>
        <hr>

        <basic-input
          v-model="workshop.PARTNERNR"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Partner-Nr."
          :horizontal="true"
          label="Partner-Nr."
        />

        <div class="form-row my-3">
          <div class="col-12">
            <!-- <basic-select
              v-model="workshop.address.contact.Kontaktart"
              :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
              placeholder="Kontaktart"
              :horizontal="true"
              :margin="false"
              label="Bevorzugte Kontaktart"
              required
              :options="humanContactOptions"
            /> -->
            <basic-input
              v-model="workshop.address.contact.Kontaktart"
              :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
              placeholder="Kontaktart"
              :horizontal="true"
              :margin="false"
              label="Bevorzugte Kontaktart"
              required
            />
          </div>
        </div>

        <basic-input
          v-model="workshop.address.contact.TEF"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Festnetznummer"
          :horizontal="true"
          label="Festnetztelefon"
        />

        <basic-tel-input
          v-model="workshop.address.contact.MOB"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          placeholder="Mobilfunknummer"
          label="Mobiltelefon"
        />

        <basic-input
          v-model="workshop.address.contact.FAX"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Telefaxnummer"
          :horizontal="true"
          label="Telefax"
        />

        <basic-input
          v-model="workshop.address.contact.MAIL"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="E-Mail Adresse"
          :horizontal="true"
          label="E-Mail"
        />

        <basic-input
          v-model="workshop.address.contact.URL"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Website-URL"
          :horizontal="true"
          label="Internet"
        />

        <basic-input
          v-model="workshop.address.contact.BETREUER1"
          class="pb-3"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Betreuernummer"
          :horizontal="true"
          label="Betreuer"
          :margin="false"
        />

        <basic-input
          v-model="workshop.address.contact.VMT"
          class="pb-3"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Vermittlernummer"
          :horizontal="true"
          label="Vermittler"
          :margin="false"
        />

        <hr>

        <div class="form-row py-3">
          <div class="col-12 col-md-4">
            Privatdaten hinzufügen
          </div>
          <div class="col-12 col-md-8 d-flex align-items-center">
            <basic-checkbox
              v-model="privateAddress"
              :margin="false"
            />
          </div>
        </div>

        <b-collapse
          id="privateDataCollapse"
          v-model="privateAddress"
        >
          <basic-input
            v-model="workshop.address.contact.TEL"
            class="py-3"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            placeholder="Festnetznummer"
            :horizontal="true"
            :margin="false"
            label="Festnetztelefon"
          />

          <basic-tel-input
            v-model="workshop.address.contact.MOBIL_PRIVAT"
            class="pb-3"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            :horizontal="true"
            :margin="false"
            placeholder="Mobilfunknummer"
            label="Mobiltelefon"
          />

          <basic-input
            v-model="workshop.address.contact.FAX_PRIVAT"
            class="pb-3"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            placeholder="Telefaxnummer"
            :horizontal="true"
            :margin="false"
            label="Telefax"
          />

          <basic-input
            v-model="workshop.address.contact.MAIL_PRIVAT"
            class="pb-3"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            placeholder="E-Mail Adresse"
            :horizontal="true"
            :margin="false"
            label="E-Mail"
          />

          <basic-input
            v-model="workshop.address.contact.URL_PRIVAT"
            class="pb-3"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            placeholder="Website-URL"
            :horizontal="true"
            label="Internet"
            :margin="false"
          />

          <div class="form-row py-3">
            <div class="col-12 col-md-4">
              Privatdaten bevorzugen
            </div>
            <div class="col-12 col-md-8 d-flex align-items-center">
              <basic-checkbox
                v-model="workshop.address.contact.PLAUSI_KOMM"
                :margin="false"
              />
            </div>
          </div>
        </b-collapse>

        <hr>

        <basic-input
          v-model="workshop.address.contact.GKC"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Verbindung"
          :horizontal="true"
          label="Verbindung"
        />
      </form>
    </template>
  </ca-card>
</template>

<script>
import BasicInput from '@/components/BaseComponents/BasicInput.vue'
import BasicSelect from '@/components/BaseComponents/BasicSelect.vue'
import BasicTypeahead from '@/components/BaseComponents/BasicTypeahead.vue'
import CaCard from '@/components/Card.vue'
import BasicCheckbox from '../../BaseComponents/BasicCheckbox.vue'
import BasicTextarea from '../../BaseComponents/BasicTextarea.vue'
import BasicTelInput from '@/components/BaseComponents/BasicTelInput.vue'
import contactType from '@/resources/enums/contactType.js'
import TitleSelectMixin from '@/components/Forms/title-select-mixin.js'

export default {
  name: 'PartnerForm',
  components: {
    BasicInput,
    BasicSelect,
    CaCard,
    BasicTypeahead,
    BasicCheckbox,
    BasicTextarea,
    BasicTelInput
  },
  mixins: [TitleSelectMixin],
  props: {
    workshop: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      contactType,
      country: '',
      specialRec: false,
      privateAddress: false
    }
  },
  computed: {
    isCompany () {
      return this.workshop.address.ANR === 'f'
    },
    humanContactOptions () {
      return Object.values(this.contactType).map(contact => ({ label: contact.human, value: contact.api }))
    }
  },
  watch: {
    workshop: {
      immediate: true,
      async handler () {
        if (!this.country && this.workshop.address.NAT.char) {
          this.country = (await this.searchCountry(this.workshop.address.NAT.char)).data[0].name
        }
        if (!this.specialRec && (this.workshop.address.BEZ || this.workshop.address.BEZ)) {
          this.specialRec = true
        }
        const contact = this.workshop.address.contact
        if (!this.privateAddress && (contact.TEL || contact.MOBIL_PRIVAT || contact.FAX_PRIVAT || contact.MAIL_PRIVAT || contact.URL_PRIVAT)) {
          this.privateAddress = true
        }
      }
    }
  },
  methods: {
    searchCityByZip (zip) {
      return this.$store.dispatch('zipcodes/find', {
        query: {
          $limit: 50,
          zip: { $regex: '^' + zip, $options: 'i' }
        }
      })
    },
    searchCityByName (name) {
      return this.$store.dispatch('zipcodes/find', {
        query: {
          $limit: 50,
          name: { $regex: '^' + name, $options: 'i' }
        }
      })
    },
    cityResultFormatter (city) {
      return `${city.zip} ${city.name}`
    },
    setCity (city) {
      if (city.zip) {
        this.workshop.address.PLZ = city.zip
      }
      if (city.name) {
        this.workshop.address.ORT = city.name
      }
    },
    setCityPo (city) {
      if (city.zip) {
        this.workshop.address.PL2 = city.zip
      }
      if (city.name) {
        this.workshop.address.POF = city.name
      }
    },
    searchCountry (char) {
      return this.$store.dispatch('countries/find', {
        query: {
          $limit: 10, char: char.toUpperCase()
        }
      })
    },
    countryResultFormatter (NAT) {
      return `${NAT.char} ${NAT.name}`
    },
    setCountry (NAT) {
      this.country = NAT.name
    }
  }
}
</script>
