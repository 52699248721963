<template>
  <div class="list-group shadow">
    <typeahead-list-item
      v-for="item of items"
      :key="item.index"
      :data="item.data"
      :html-text="highlight(item.result)"
      @click.native="handleHit(item, $event)"
    >
      <slot />
    </typeahead-list-item>
  </div>
</template>

<script>
import TypeaheadListItem from './TypeaheadListItem.vue'

function sanitize (text) {
  return text.replace(/</g, '&lt;').replace(/>/g, '&gt;')
}
function escapeRegExp (str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}

export default {
  name: 'TypeaheadList',
  components: {
    TypeaheadListItem
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    searchTerm: {
      type: String,
      default: ''
    }
  },
  computed: {
    highlight () {
      return (text) => {
        text = sanitize(text)
        if (this.searchTerm.length === 0) {
          return text
        }
        const re = new RegExp(this.escapedQuery, 'gi')
        return text.replace(re, '<strong>$&</strong>')
      }
    },
    escapedQuery () {
      return escapeRegExp(sanitize(this.searchTerm))
    }
  },
  methods: {
    handleHit (item, evt) {
      this.$emit('hit', item)
      evt.preventDefault()
    }
  }
}
</script>
