<template>
  <div class="flex-grow-1">
    <input
      ref="input"
      v-model="searchTerm"
      v-bind="$attrs"
      :class="{'is-invalid': isInvalid}"
      class="form-control"
      type="search"
      autocomplete="off"
      @focus="isFocused = true"
      @blur="handleBlur"
    >
    <typeahead-list
      v-show="isFocused && items.length > 0"
      :items="formattedItems"
      :search-term="searchTerm"
      class="typeahead-list"
      @hit="handleHit"
    >
      <slot />
    </typeahead-list>
  </div>
</template>
<script>
import TypeaheadList from './TypeaheadList'
import debounce from 'lodash.debounce'

export default {
  components: {
    TypeaheadList
  },
  props: {
    initialValue: {
      type: [String, Object, Number, Date],
      default: null
    },
    inputFormatter: {
      type: Function,
      default: (value) => value
    },
    resultFormatter: {
      type: Function,
      default: (value) => value
    },
    query: {
      type: Function,
      default: (value) => value
    },
    isInvalid: {
      type: Boolean,
      default: false
    },
    autoSelectFirst: {
      type: Boolean,
      default: false
    },
    strict: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      searchTerm: '',
      items: [],
      selectedItem: null,
      isFocused: false,
      pending: false
    }
  },
  computed: {
    formattedItems () {
      return this.items.map(this.formatItem)
    }
  },
  watch: {
    searchTerm: {
      handler: 'search'
    },
    initialValue: {
      handler: 'setInitialValue',
      immediate: true
    }
  },
  methods: {
    formatItem (item, index) {
      return {
        index,
        data: item,
        input: this.inputFormatter(item),
        result: this.resultFormatter(item)
      }
    },
    setInitialValue () {
      if (this.initialValue) {
        const formattedInitalValue = this.formatItem(this.initialValue)
        // Der initiale Wert wird nur gesetzt wenn der sich vom letztem ausgewählten Wert unterscheidet. Das sollte Loops verhindern
        if (this.selected && formattedInitalValue.input === this.selected.input) return
        this.selectedItem = formattedInitalValue
        this.searchTerm = formattedInitalValue.input
      } else {
        this.selectedItem = null
        this.searchTerm = ''
      }
    },
    debouncedSearch: debounce(async function (term) {
      if (term) {
        const request = await this.query(term)
        if (Array.isArray(request)) {
          this.items = request
        } else {
          this.items = request.data
        }
        if (this.autoSelectFirst && this.items.length === 1) {
          this.handleHit(this.formattedItems[0])
        }
        this.pending = false
      }
    }, 200),
    search (term) {
      this.pending = true
      this.items = []
      this.debouncedSearch(term)
    },
    handleHit (item) {
      this.selectedItem = item
      this.searchTerm = item.input
      this.$emit('hit', item.data)
      if (this.$refs.input) this.$refs.input.blur()
      this.isFocused = false
    },
    handleBlur (evt) {
      const tgt = evt.relatedTarget
      if (tgt && tgt.classList.contains('vbst-item')) {
        return
      }
      if (this.strict && (!this.selectedItem || this.searchTerm !== this.selectedItem.input)) {
        this.searchTerm = ''
        this.$emit('unselect')
      }
      if (!this.strict) {
        this.$emit('input', this.searchTerm)
      }
      this.isFocused = false
    }
  }
}
</script>

<style scoped>
  .typeahead-list {
  padding-top: 5px;
  position: absolute;
  max-height: 350px;
  overflow-y: auto;
  z-index: 999;
  }
</style>
