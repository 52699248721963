<template>
  <ca-card class="mb-3">
    <template #header>
      <div class="row">
        <div class="col d-flex align-items-center">
          <h4 class="m-0">
            Bankdaten
          </h4>
        </div>
        <div class="col-auto">
          <button
            class="btn btn-primary"
            @click="addBank"
          >
            <i class="fas fa-plus mr-2" />
            Konto hinzufügen
          </button>
        </div>
      </div>
    </template>
    <template #body>
      <BankCollapseItem
        v-for="(bank, index) in bankDetailsList"
        :key="bank._id"
        ref="BankCollapseItem"
        :bank-details="bank"
        :index="index"
        :edit-mode="editMode"
        @remove-bank="removeBank"
      />
    </template>
  </ca-card>
</template>

<script>
import CaCard from '@/components/Card'
import BankCollapseItem from '@/components/Forms/Bank/BankCollapseItem.vue'

export default {
  name: 'BankDataForm',
  components: {
    CaCard,
    BankCollapseItem
  },
  props: {
    bankDetailsList: {
      type: Array,
      required: true
    },
    editMode: {
      type: Boolean
    }
  },
  methods: {
    addBank () {
      for (const item of this.$refs.BankCollapseItem) {
        item.visible = false
      }
      const length = this.bankDetailsList.push(new this.$FeathersVuex.api.BankAccount())
      this.bankDetailsList[length - 1].VWZ = `Konto ${length}`
    },
    removeBank (index) {
      this.bankDetailsList.splice(index, 1)
      if (index > 0) {
        this.$refs.BankCollapseItem[index - 1].visible = true
      }
    }
  }
}
</script>
