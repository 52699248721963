<template>
  <a
    class="vbst-item list-group-item list-group-item-action"
    tabindex="0"
  >
    <slot v-bind="{ data, htmlText }">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <span v-html="htmlText" />
    </slot>
  </a>
</template>

<script>

export default {
  name: 'TypeaheadListItem',
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    htmlText: {
      type: String,
      default: ''
    }
  }
}
</script>
