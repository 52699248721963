<template>
  <basic-form-group
    :id="id"
    :label-is-up="labelIsUp"
    :label="$attrs.label"
    :required="$attrs.required"
    :horizontal="$attrs.horizontal"
    :columns="$attrs.columns"
    :margin="$attrs.margin"
    :small="$attrs.small"
    :infotext="$attrs.infotext"
    :align-label-center="$attrs['align-label-center']"
  >
    <template slot="label">
      <slot name="label" />
    </template>
    <template slot="input">
      <textarea
        :id="id"
        v-model="model"
        v-validate="getValidation"
        :class="{'is-invalid': veeFields[veeField] && veeFields[veeField].validated && veeFields[veeField].invalid}"
        :name="veeField"
        :data-vv-as="name"
        v-bind="$attrs"
        class="form-control"
        v-on="listeners"
      />
      <validation-feedback :name="veeField" />
    </template>
  </basic-form-group>
</template>

<script>
import basicComponentMixin from './basic-component-mixin.js'
export default {
  name: 'BasicTextarea',
  mixins: [basicComponentMixin]
}
</script>
