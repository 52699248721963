import titleType from '@/resources/enums/titles/titleType.js'
import academicTitleType from '@/resources/enums/titles/academicTitleType.js'
import nobleTitleType from '@/resources/enums/titles/nobleTitleType.js'

export default {
  data () {
    return {
      titleType,
      academicTitleType,
      nobleTitleType
    }
  },
  computed: {
    humanGenOptions () {
      const options = Object.values(this.titleType).filter(title => title.showForSelect).map(title => ({ label: title.human, value: title.api }))
      return options
    },
    humanTitOptions () {
      const options = Object.values(this.academicTitleType).map(title => ({ label: title.human, value: title.api }))
      options.unshift({ value: null })
      options.unshift({ label: 'Akademischer Titel', disabled: true })
      return options
    },
    humanPreOptions () {
      const options = Object.values(this.nobleTitleType).map(title => ({ label: title.human, value: title.api }))
      options.unshift({ value: null })
      options.unshift({ label: 'Adelstitel', disabled: true })
      return options
    }
  }
}
