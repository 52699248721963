<template>
  <basic-form-group
    :id="id"
    :label-is-up="labelIsUp"
    :required="$attrs.required"
    :horizontal="$attrs.horizontal"
    :columns="$attrs.columns"
    :margin="$attrs.margin"
    :small="$attrs.small"
    :infotext="$attrs.infotext"
    :align-label-center="$attrs.alignLabelCenter"
  >
    <template
      v-if="$attrs.horizontal"
      slot="label"
    >
      <slot name="label" />
    </template>
    <template slot="input">
      <input
        :id="id"
        v-model="model"
        v-validate="getValidation"
        :data-vv-as="name"
        :name="veeField"
        v-bind="$attrs"
        type="checkbox"
        class="switch-input"
      >
      <label
        :for="id"
        class="switch-label mb-0"
        :class="{'right': right}"
      >
        <span class="d-inline-block">
          {{ $attrs.label }}
          <span class="toggle--on">{{ swtichStateName.active }}</span>
          <span class="toggle--off">{{ swtichStateName.inactive }}</span>
          <span
            v-if="infotext || $scopedSlots.slotinfotext"
            class="d-inline-block"
          >
            <i
              :id="id+'infotext'"
              class="far fa-info-circle text-gray-4 ml-2"
            />
            <b-popover
              :target="id+'infotext'"
              placement="top"
              triggers="hover focus"
              :content="infotext"
            >
              <slot name="slotinfotext" />
            </b-popover>
          </span>
        </span>
      </label>
      <validation-feedback :name="veeField" />
    </template>
  </basic-form-group>
</template>

<script>
import basicComponentMixin from './basic-component-mixin.js'
export default {
  name: 'BasicSwitch',
  mixins: [basicComponentMixin],
  props: {
    right: {
      default: false,
      type: Boolean
    },
    swtichStateName: {
      default: () => {
        return {
          active: 'aktiviert',
          inactive: 'deaktiviert'
        }
      },
      type: Object
    },
    infotext: {
      type: String,
      default: ''
    }
  },
  methods: {
    toggleState () {
      this.model = !this.model
    }
  }
}
</script>

<style lang="scss" scoped>
    @import "@/assets/scss/_variables.scss";

$ballSize: 15px;
$spacer: 5px;

$onColor: $success;
$offColor: $primary;
$disabledColor: $gray-4;

.switch-input {
  display: none;
  &:checked:disabled ~ .switch-label:after{
    background-color: $disabledColor;
  }
  &:checked:disabled ~ .switch-label::before{
    background-color: $gray-3;
  }
  &:disabled ~ .switch-label:after{
    background-color: $disabledColor;
  }
}
.switch-label {
  position: relative;
  display: inline-block;
  cursor: pointer;
  text-align: left;
  padding: 0 0 0 calc( #{$ballSize} * 2 + #{$spacer} + 10px);

    &.right{
      padding: 0 calc( #{$ballSize} * 2 + #{$spacer} + 10px) 0 0;

      &:before{
      right: 0px;
      left: inherit;
    }

    &:after{
      right: calc(#{$spacer} / 2 + #{$ballSize});
      left: inherit;
    }
  }

  &:before, &:after{
    content: "";
    position: absolute;
    margin: 0;
    outline: 0;
    top: 50%;
    -ms-transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }

  &:before{
    left: 0px;
    width: calc( #{$ballSize} * 2 + #{$spacer} );
    height: calc( #{$ballSize} + #{$spacer} );
    background-color: $gray-3;
    border-radius: 100px;
  }

  &:after{
    left: calc($spacer / 2);
    width: $ballSize;
    height: $ballSize;
    background-color: $offColor;
    border-radius: 50%;
  }

  .toggle--on{
  display: none;
  }

  .toggle--off{
  display: inline-block;
  }
}
.switch-input:checked + .switch-label:before {
  background-color: lighten($onColor, 20%);
}
.switch-input:checked + .switch-label:after {
  background-color: $onColor;
  -ms-transform: translate(100%, -50%);
  -webkit-transform: translate(100%, -50%);
  transform: translate(100%, -50%);
}
.switch-input:checked + .switch-label .toggle--on {
  display: inline-block;
}
.switch-input:checked + .switch-label .toggle--off {
  display: none;
}
</style>
