<template>
  <basic-form-group
    :id="$attrs.id"
    :label-is-up="labelIsUp"
    :label="$attrs.label"
    :required="$attrs.required"
    :horizontal="$attrs.horizontal"
    :columns="$attrs.columns"
    :margin="$attrs.margin"
    :small="$attrs.small"
    :infotext="$attrs.infotext"
    :align-label-center="$attrs.alignLabelCenter"
  >
    <template slot="label">
      <slot name="label" />
    </template>
    <template slot="input">
      <div class="input-group">
        <div
          v-if="$scopedSlots.prepend"
          class="input-group-prepend"
        >
          <slot name="prepend" />
        </div>
        <typeahead
          :id="id"
          v-model="model"
          v-validate="getValidation"
          :initial-value="model"
          :is-invalid="veeFields[veeField] && veeFields[veeField].validated && veeFields[veeField].invalid"
          :name="veeField"
          :data-vv-as="name"
          :auto-select-first="autoSelectFirst"
          v-bind="$attrs"
          @hit="$emit('input', $event)"
          @unselect="$emit('unselect')"
          v-on="listeners"
        />
        <div
          v-if="$scopedSlots.append"
          class="input-group-append"
        >
          <slot name="append" />
        </div>
      </div>
    </template>
  </basic-form-group>
</template>

<script>
import Typeahead from '../Typeahead/Typeahead.vue'
import basicComponentMixin from './basic-component-mixin.js'

export default {
  name: 'BasicTypeahead',
  components: {
    Typeahead
  },
  mixins: [basicComponentMixin],
  props: {
    autoSelectFirst: {
      type: Boolean,
      default: false
    }
  }
}
</script>
