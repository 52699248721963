<template>
  <ca-card class="mb-3">
    <template #header>
      <h4 class="mb-0">
        Information
      </h4>
    </template>
    <template #body>
      <form
        novalidate
        @submit.prevent
      >
        <basic-file-upload
          v-model="file"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Bilder hochladen"
          :horizontal="true"
          accept="image/*"
          label="Partnerfoto"
          :align-label-center="false"
          @uploaded="addFile"
          @upload-pending="uploadPending = $event"
          @removed="removeFile"
        />
        <div class="form-row">
          <div class="col-12 col-md-4" />
          <div class="col-12 col-md-8 d-flex align-items-center">
            <small>Empfohlene Bildgröße: 320 x 220 Pixel</small>
          </div>
        </div>

        <basic-input
          v-model="workshop.information.ZIELGR"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Zielgruppe"
          :horizontal="true"
          label="Zielgruppe"
        />

        <basic-input
          v-model="workshop.information.AKTIONEN"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Teilnahme an Aktionen"
          :horizontal="true"
          label="Aktionen"
        />
      </form>
    </template>
  </ca-card>
</template>

<script>
import BasicInput from '@/components/BaseComponents/BasicInput'
import CaCard from '@/components/Card'
import BasicFileUpload from '@/components/BaseComponents/BasicFileUpload'

export default {
  name: 'PartnerInfoForm',
  components: {
    BasicInput,
    CaCard,
    BasicFileUpload
  },
  props: {
    workshop: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      file: []
    }
  },
  methods: {
    addFile (file) {
      this.workshop.information.PARTNERFOTO = file._id
    },
    removeFile (index) {
      delete this.workshop.information.PARTNERFOTO
    }
  }
}
</script>
